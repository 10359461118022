<template>
    <div>
      <form class="uk-form-stacked" v-if="!editButtons">
        <div class="uk-width-1-1">
          <div class="uk-background-default uk-padding-small uk-box-shadow-medium">
            <div class="uk-margin">
              <label class="uk-form-label">Buttons</label>
              <div
                v-for="(button, index) in localData.items"
                :key="index"
                class="uk-margin-small">
                <div class="uk-flex uk-flex-middle">
                  <button type="button" class="uk-button uk-flex uk-flex-middle uk-background-muted uk-text-left" @click.prevent="edit(button)">
                    {{ button.props.content }}
                    <span data-uk-icon="chevron-right"></span>
                  </button>
                  <a href="#" uk-icon="trash" class="uk-margin-left uk-text-muted" @click.prevent="removeItem(index)"></a>
                </div>
              </div>
              <div class="uk-margin">
                <button class="uk-button-secondary uk-button uk-border-rounded uk-button-small" @click.prevent="add">Button hinzufügen</button>
              </div>
            </div>
            <hr>
            <div class="uk-margin">
              <label class="uk-form-label">Größe</label>
              <select class="uk-select" v-model="form.button_size">
                <option value="small">Klein</option>
                <option value>Standart</option>
                <option value="large">Groß</option>
              </select>
              <div class="uk-margin-small-top">
                <label>
                  <input class="uk-checkbox" type="checkbox" v-model="form.full"> Komplette Breite
                </label>
              </div>
            </div>
            <div class="uk-margin">
              <label class="uk-form-label">Abstand</label>
              <select class="uk-select" v-model="form.gutter">
                <option value="small">Klein</option>
                <option value="medium">Mittel</option>
                <option value>Standart</option>
                <option value="large">Groß</option>
              </select>
            </div>
          </div>
          <div class="uk-margin">
            <button class="uk-button uk-button-default" @click.prevent="showSettings = !showSettings">
              Einstellungen
              <span uk-icon="arrow-right" v-if="!showSettings"></span>
              <span uk-icon="arrow-down" v-if="showSettings"></span>
            </button>
          </div>
        </div>
        <div class="uk-width-1-1 uk-margin" v-if="showSettings">
          <div class="uk-background-default uk-padding-small uk-box-shadow-medium">
              <general-settings :form="form" />
          </div>
        </div>
      </form>
      <div class="uk-width-1-1 uk-margin" v-else>
        <div class="uk-background-default uk-padding-small uk-box-shadow-medium">
          <button-item-edit :data="this.editButtonsData" />
        </div>
      </div>
  </div>
</template>

<script>
import axios from 'axios'
import ButtonItemEdit from './ButtonItemEdit'
import GeneralSettings from './../GeneralSettings'
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  components: {
    ButtonItemEdit,
    GeneralSettings
  },
  data() {
    return {
      form: {},
      localData: {},
      editButtons: false,
      editButtonsData: null,
      showSettings: false
    }
  },
  mounted() {
    this.form = this.data.props;
    this.localData = this.data;
  },
  methods: {
    async add() {
      let defaultProps = await axios.get(`./../../../assets/json/defaultProps/buttonItem.json`)
      const elemObj = {
        type: 'button_item',
        props: defaultProps.data
      };
      this.localData.items.push(elemObj)
      this.edit(elemObj)
    },
    edit(button) {
      this.editButtons = true;
      this.editButtonsData = button;
    },
    removeItem(index) {
      this.localData.items.splice(index, 1)
    }
  }
}
</script>
